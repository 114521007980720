/**
 * This is based off of https://github.com/vendasta/VA-vcomponents/blob/master/product-navbar/product_navbar.ts
 */
export namespace Request {
    export function ajaxPost(url: string, body: any, callback: (response: any, statusCode?: number) => any, api_key?: string) {
        const xhr = this.createCORSRequest('POST', url);
        if (!xhr) {
            throw new Error('CORS not supported');
        }

        xhr.setRequestHeader('Content-type', 'multipart/form-data');
        if (api_key) {
            xhr.setRequestHeader('Authorization', api_key);
        }

        xhr.responseType = 'json';
        xhr.withCredentials = true;
        xhr.onload = () => {
            callback((typeof xhr.response === 'string') ? JSON.parse(xhr.response) : xhr.response, xhr.status);
        };
        xhr.onerror = () => {
            throw new Error('Could not get data using CORS');
        };
        xhr.send(JSON.stringify(body));
    }

    export function ajaxGet(url: string, callback: (response: any) => any) {
        const xhr = new XMLHttpRequest();
        xhr.open('GET', url, true);

        if (!xhr) {
            throw new Error('CORS not supported');
        }

        xhr.responseType = 'json';
        xhr.withCredentials = false;
        xhr.onload = () => {
            callback(xhr.response);
        };
        xhr.onerror = () => {
            throw new Error('Could not get data using CORS');
        };
        xhr.send();
    }

    export function createCORSRequest(method, url) {
        let xhr = new XMLHttpRequest();

        if ('withCredentials' in xhr) {
            // Check if the XMLHttpRequest object has a 'withCredentials' property.
            // 'withCredentials' only exists on XMLHTTPRequest2 objects.
            xhr.open(method, url, true);
        } else if (typeof window['XDomainRequest'] !== 'undefined') {
            // Otherwise, check if XDomainRequest.
            // XDomainRequest only exists in IE, and is IE's way of making CORS requests.
            xhr = new window['XDomainRequest']();
            xhr.open(method, url);
        } else {
            // Otherwise, CORS is not supported by the browser.
            xhr = null;
        }

        return xhr;
    }

    export function isSuccessStatus(statusCode: number|undefined): boolean {
        if(!statusCode){
            return false;
        }
        return statusCode >= 200 && statusCode < 300;
    }
}

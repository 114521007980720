import { Configuration } from "./configuration";
import * as $ from "jquery";
import { Request } from "./request";
import '../lib/autoComplete/auto-complete.css';
import * as stylesheet from "./styles/styles.scss";
import {applyBootstrapColorsToForm, Style} from './style_configuration'
require('../lib/deps/jsv');
import * as jsonform from 'jsonform';
import {buildBusinessSearch} from "./extrafieldtypes/businesssearch";

interface FormSubmission {
    form_id: string;
    values: Object;
}

interface FormSubmissionRequestBody {
    submission: FormSubmission;
    recaptchaToken?: string;
}

export class Widget {
  schema: any;
  formUI: any;
  fieldValues: any;
  config: Configuration;
  recaptchaSiteKey: string;
  recaptchaLoaded: boolean = false;

  constructor(
    configuration: Configuration,
    schema: any,
    formUI: any,
    fieldValues: any
  ) {
    this.config = configuration;
    this.schema = schema;
    this.formUI = formUI;
    this.fieldValues = fieldValues;
  }

  buildContainer(form: HTMLElement): void {
    this.addRecaptchaScript();
    let formIDClass = stylesheet["form-widget"];
    if (this.config.formId) {
      formIDClass += ` form-widget form-widget-2`; // need a second class to out-specify the Bootstrap library, without making it un-overridable with an !important
      formIDClass += ` form-widget-${this.config.formId}`;
    }
    form.setAttribute("class", formIDClass);

    const styles = form.style;
    const styleConfiguration = this.config.styleConfiguration;
    this.extendJSONForm(jsonform, styleConfiguration, formIDClass);

    styles.backgroundColor = styleConfiguration.backgroundColor;
    styles.borderColor = styleConfiguration.borderColor;
    styles.borderRadius = styleConfiguration.borderRadius;
    styles.borderWidth = styleConfiguration.borderWidth;
    styles.borderStyle = styleConfiguration.borderStyle;
    styles.padding = styleConfiguration.padding;
    styles.color = styleConfiguration.primaryFontColor;
    styles.width = styleConfiguration.width;
    styles.boxSizing = 'border-box';

    let formUI = [...this.formUI];
    if (this.config.hideSubmit) {
      formUI = formUI.filter((field)=> field.type !== "submit");
    }
    $(form).jsonForm({
      schema: this.schema,
      form: formUI,
      value: this.fieldValues,
      // this is necessary because of an issue in jsonform not being able to detect the JSV dependency
      // issue link: https://github.com/jsonform/jsonform/issues/362
      validate: (globalThis.JSONFormValidator).createEnvironment("json-schema-draft-03"),
      onSubmitValid: (values) => {
        if (this.config.previewMode) {
          return;
        }
        // disable submit button to avoid double submissions
        $(form).find(`input[type='submit']`).prop('disabled', true);
        if (this.config?.recaptchaSiteKey) {
          this.runRecaptchaAndSubmit(form, values);
        } else {
          this.submitForm(form, values, '');
        }
      },
    });
    applyBootstrapColorsToForm(form, styleConfiguration);
  }

  private runRecaptchaAndSubmit(form: HTMLElement, values: any): void {
    const grecaptcha = window["grecaptcha"];
    if (!grecaptcha) {
      this.showSubmissionFailedMessage(form);
      return;
    }
    grecaptcha.ready(() => {
      try {
        grecaptcha
            .execute(this.config.recaptchaSiteKey, {
              // google api doesn't accept action names with anything different from A-Za-z/_
              action: `submit_form_${this.config?.formId?.replace(
                  /[^A-Za-z/_]/g,
                  ""
              )}`,
            })
            .then((token) => {
              this.submitForm(form, values, token);
            });
      } catch (e) {
        // this try/catch protects against grecaptcha.execute errors
        this.showSubmissionFailedMessage(form);
      }
    });
  }

  private submitForm(form: HTMLElement, values: any, token: string): void {
    let requestBody: FormSubmissionRequestBody = {
      submission: {
        form_id: this.config.formId,
        values: JSON.stringify(values),
      }
    }
    if (token) {
        requestBody.recaptchaToken = token;
    }

    Request.ajaxPost(
      this.config.baseURL +
      "/forms.v1.FormSubmissionService/CreateFormSubmission",
      requestBody,
      (response, statusCode) => {
        if (!Request.isSuccessStatus(statusCode)) {
          this.showSubmissionFailedMessage(form);
          return;
        }
        if (response?.redirectUrl) {
          window.location = response.redirectUrl;
        } else {
          this.showSuccessMessage(form);
        }
      }
    );
  }

  private extendJSONForm(jsonForm: any, styles: Style, formIDClass: string): void {
    if (!!jsonForm?.JSONForm?.fieldTypes) {
      jsonForm.JSONForm.fieldTypes['business_search'] = buildBusinessSearch(this.config, styles, formIDClass);
    }
  }

  addRecaptchaScript(): void {
    if (!this.config?.recaptchaSiteKey) {
      return;
    }
    // if we already have the grecaptcha installed, skip loading the script
    if (window["grecaptcha"]) {
      return;
    }
    const functionName = "_" + (Math.floor(Math.random() * 1000001) + 1000000);
    window[functionName] = () => {
      this.recaptchaLoaded = true;
    };
    const script = document.createElement("script");
    script.setAttribute(
      "src",
      `https://www.google.com/recaptcha/api.js?render=${this.config?.recaptchaSiteKey}&onload=${functionName}`
    );
    document.getElementsByTagName("head")[0].appendChild(script);
  }

  showSuccessMessage(form: HTMLElement): void {
    form.innerHTML = "<h1>Thank you</h1>";
    form.innerHTML += "<p>Your submission was successful.</p>";
  }

  showSubmissionFailedMessage(form: HTMLElement): void {
    form.innerHTML = "<h1>Form submission failed</h1>";
    form.innerHTML += "<p>An error kept your form from being submitted.</p>";
    form.innerHTML += "<p>Try submitting this form again to continue.</p>";
  }
}

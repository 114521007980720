import {Widget} from '../widget';
import * as $ from "jquery";
import {Style} from "../style_configuration";
import {Configuration} from "../configuration";

const GOOGLE_API_KEY = 'AIzaSyDxrR1bk6d2TRa4orIDF05H3UlhvIXRRC0';

export interface DropdownItem {
    description: string;
    mainText: string;
    placeId: string;
    secondaryText: string;
}
export interface AutoCompleteResponse {
    predictions: DropdownItem[];
}

interface DisplayName {
    text: string;
    languageCode: string;
}

interface AddressComponent {
    longText?: string;
    shortText?: string;
    types?: string[];
    languageCode?: string;
}

interface PlaceLocation {
    latitude: number;
    longitude: number;
}

interface PlaceResult {
    displayName?: DisplayName;
    addressComponents?: AddressComponent[];
    internationalPhoneNumber?: string;
    websiteUri?: string;
    location?: PlaceLocation;
}

interface AutocompletePrediction {
    structured_formatting: { main_text: string, secondary_text: string };
    description: string;
    place_id: string;
}

// Move all field IDs into SDK
const StandardIds = {
    CompanyName: 'FieldID-b2a5d86e-f9ae-11ed-be56-0242ac120002',
    CompanyPrimaryAddressLine1: 'FieldID-36785fbc-9c40-4938-92e3-b262d40ef6bb',
    CompanyPrimaryAddressLine2: 'FieldID-ac4c2ea1-f8d1-4bdd-8a42-a1ad4127eef6',
    CompanyPrimaryAddressCity: 'FieldID-33b7b0a0-82ed-471b-baa1-87ab958f90d2',
    CompanyPrimaryAddressState: 'FieldID-184fe9c2-b3a8-47c5-85dc-7c3a285c17ac',
    CompanyPrimaryAddressPostalCode: 'FieldID-b0e75bc5-59f7-4c54-98a8-510b9ec982ec',
    CompanyPrimaryAddressCountry: 'FieldID-daa0c91f-8a0e-4c90-be9c-e53a73789edc',
    CompanyWebsite: 'FieldID-f25628fb-eeb3-4d99-8737-44b403758837',
    CompanyPhoneNumber: 'FieldID-fc7ff4ae-b753-4650-9961-42b07bcc6ac6',
    CompanyTimezone: 'FieldID-3ebce03e-260b-4573-93b0-85f5582ade3d',
}

const extractFromAddress = (
    components: AddressComponent[],
    type: string,
    name_type = 'longText',
): string => {
    return (
        components
            .filter((component) => component.types.indexOf(type) === 0)
            .map((item) => item[name_type])
            .pop() || null
    );
}

const buildCompanyInfoFromPlace = (placeId: string, place: PlaceResult, timezone: string): Map<string, string> => {
    const map = new Map<string, string>();
    map.set(StandardIds.CompanyName, place?.displayName?.text);
    map.set(StandardIds.CompanyPrimaryAddressLine1, extractFromAddress(place?.addressComponents, 'route'));
    map.set(StandardIds.CompanyPrimaryAddressLine2, extractFromAddress(place?.addressComponents, 'floor'));
    map.set(StandardIds.CompanyPrimaryAddressCity, extractFromAddress(place?.addressComponents, 'locality'));
    map.set(StandardIds.CompanyPrimaryAddressState, extractFromAddress(place?.addressComponents, 'administrative_area_level_1'));
    map.set(StandardIds.CompanyPrimaryAddressPostalCode, extractFromAddress(place?.addressComponents, 'postal_code'));
    map.set(StandardIds.CompanyPrimaryAddressCountry, extractFromAddress(place?.addressComponents, 'country'));
    map.set(StandardIds.CompanyWebsite, place?.websiteUri);
    map.set(StandardIds.CompanyPhoneNumber, place?.internationalPhoneNumber);
    map.set(StandardIds.CompanyTimezone, timezone);
    return map;
}

interface Location {
    lat: number;
    lng: number;
}

export class BusinessAutocomplete {
    businessNameBox: any;
    autoCompleteUrl: string;
    private config: Configuration;
    private className: string;
    private styles: Style;
    private node: Object;
    private currentLocation: Location;

    constructor(configuration: Configuration, className: string, styles: Style, node: Object) {
        this.config = configuration;
        this.className = className;
        this.styles = styles;
        this.node = node;
        this.businessNameBox = this.createAutoComplete();
    }

    private createAutoComplete(): any {
        let autoComplete = require('../../lib/autoComplete/auto-complete.min.js');
        let self = this;

        // done primarily to pass self into the renderItem context
        let renderItemFn = (item) => {
            return this.renderItem(item, self);
        };
        let onSelect = (event: Event, term: string, item: HTMLDivElement) => {
            let placeId = item.getAttribute('place-id');
            if (!self.node['id']) {
                return;
            }
            self.populatePlaceDetails(placeId);
        };
        let sourceFn = (searchTerm, suggest) => {
            self.getChoices(searchTerm.toLowerCase(), suggest);
        };
        return new autoComplete({
            selector: `#${this.node['id']}`,
            minChars: 2,
            delay: 300,
            source: sourceFn,
            renderItem: renderItemFn,
            onSelect: onSelect,
            menuClass: this.className,
        });
    }


    private renderItem(item: DropdownItem, self): string {
        return '<div class="autocomplete-suggestion" place-id="' + item.placeId + '" data-val="' + item.description + '">' +
                    '<span>' + item.mainText + '</span></br>' +
                    '<span class="secondary-text">' + item.secondaryText + '</span>' +
        '</div>';
    }
s
    private async getChoices(search_term: string, suggest: Function): Promise<void> {
        try {
            await this.initCurrentLocation();
            const response = await fetch(`${this.config.baseURL}/place/autocomplete`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    form_id: this.config.formId,
                    search_term: search_term,
                    latitude: this.currentLocation.lat,
                    longitude: this.currentLocation.lng,
                }),
            })
            const data = await response.json();
            suggest(BusinessAutocomplete.parseAutoCompleteResponse(data.predictions));
        } catch (error) {
            // Intentionally left empty to gobble errors from partner sites
        }
    }

    private async populatePlaceDetails(placeId: string): Promise<void> {
        try {
            const response = await fetch(`https://places.googleapis.com/v1/places/${placeId}?fields=displayName,addressComponents,websiteUri,internationalPhoneNumber,location&key=${GOOGLE_API_KEY}`)
            const place = await response.json();
            if (place === null) {
                return;
            }
            const timezone = await this.getTimezone(place);
            const companyInfo = buildCompanyInfoFromPlace(placeId, place, timezone);
            companyInfo.forEach((value, key) => {
                this.updateField(key, value);
            });
        } catch (error) {
            // Intentionally left empty to gobble errors from partner sites
        }
    }

    private updateField(fieldId: string, value: string): void {
        if ($(`input[name="${fieldId}"]`).length > 0) {
            $(`input[name="${fieldId}"]`).val(value);
        }
    }

    private async getTimezone(place: PlaceResult): Promise<string> {
        const paramLat = place?.location?.latitude;
        const paramLng = place?.location?.longitude;

        try {
            const response = await fetch(`https://maps.googleapis.com/maps/api/timezone/json?location=${paramLat},${paramLng}&timestamp=${Date.now() / 1000}&key=${GOOGLE_API_KEY}`);
            const data = await response.json();
            return data.timeZoneId;
        } catch (error) {
            return '';
        }
    }

    private async initCurrentLocation(): Promise<void> {
        if (this.currentLocation) {
            return;
        }
        try {
            const response = await fetch(`https://www.googleapis.com/geolocation/v1/geolocate?key=${GOOGLE_API_KEY}`, {
                method: 'POST',
                body: JSON.stringify({}),
            });
            const data = await response.json();
            this.currentLocation = data.location;
        } catch (error) {
            // Intentionally left empty to gobble errors from partner sites
        }
    }

    public static parseAutoCompleteResponse(predictions: AutocompletePrediction[]): DropdownItem[]  {
        let choices = (predictions || []).map((prediction: AutocompletePrediction) =>({
            mainText: prediction.structured_formatting.main_text,
            description: prediction.description,
            placeId: prediction.place_id,
            secondaryText: prediction.structured_formatting.secondary_text,
        }));
        return choices;
    }
}

import { Configuration } from "./configuration";
import { Widget } from "./widget";
import { Request } from "./request";

(function () {
    function init() {
        const scriptElement = document.getElementById('__custom_form_widget');
        const configuration = new Configuration(scriptElement);

        configuration.getWidgetConfig((response, statusCode) => {
            if (!Request.isSuccessStatus(statusCode) && !configuration.previewMode) {
                return;
            }

            const schema = JSON.parse(response?.jsonSchema || "{}");
            const formUI = JSON.parse(response?.jsonUiSchema || "{}");
            configuration.setStyle(response?.styles);
            configuration.setRecaptchaSiteKey(response?.recaptchaSiteKey ?? '')
            const fieldValues = configuration.populateFieldsByQueryParam(response?.fieldQueryParams || []);
            const form = document.createElement('form');
            scriptElement.insertAdjacentElement('afterend', form);
            const widget = new Widget(configuration, schema, formUI, fieldValues);
            widget.buildContainer(form);
        });
    }

    // Initialize widget in background without blocking anything else
    setTimeout(() => {
        init();
    }, 0);
})();

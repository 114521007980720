import * as $ from "jquery";

const Color = require('color');

export interface    Style {
    // These are mostly styles for the form itself, not fields or buttons.
    // There may be some we can remove, now that we're using Bootstrap.
    backgroundColor: string;
    borderColor: string;
    borderRadius: string;
    borderWidth: string;
    borderStyle: string;
    width: string;
    padding: string;

    // This is the color of text in a box or other non-button place,
    // which is a bit confusing since the text in a button in Material would
    // be something like textOnPrimaryColor.
    primaryFontColor: string;

    // these are named like Material / Bootstrap, for primary, error, disabled...
    primaryColor: string;
    onPrimaryColor: string;
}

export const defaultStyles: Style = {
    backgroundColor: '#FFFFFF',

    borderColor: '#FFFFFF',
    borderRadius: '0px',
    borderWidth: '1px',
    borderStyle: 'solid',

    width: "300px",
    padding: '20px',

    primaryFontColor: '#222222',

    primaryColor: '#0b6efd',
    onPrimaryColor: '#ffffff',
};

export function applyBootstrapColorsToForm(form: HTMLElement, styles: Style) {
    const buttons = $(form).find('.btn-primary');

    buttons.css('--bs-btn-color', styles.onPrimaryColor);
    buttons.css('--bs-link-color', styles.onPrimaryColor);
    buttons.css('--bs-btn-bg', styles.primaryColor);
    buttons.css('--bs-btn-border-color', styles.primaryColor);

    buttons.css('--bs-btn-hover-color', styles.onPrimaryColor);
    buttons.css('--bs-btn-hover-bg', lightenOrDarkenColor(styles.primaryColor, 0.15));
    buttons.css('--bs-link-hover-color', styles.onPrimaryColor);

    const hoverBorder = lightenOrDarkenColor(styles.primaryColor, 0.15);
    buttons.css('--bs-btn-hover-border-color', hoverBorder);
    buttons.css('--bs-btn-focus-shadow-rgb', Color(contrastColor(styles.primaryColor)).mix(Color(styles.primaryColor), 0.15));

    const activeBg = lightenOrDarkenColor(styles.primaryColor, 0.2)
    buttons.css('--bs-btn-active-color', contrastColor(activeBg));
    buttons.css('--bs-btn-active-bg', activeBg);
    buttons.css('--bs-btn-active-border-color', activeBg);
    buttons.css('--bs-btn-active-shadow', 'inset 0 3px 5px rgba(0 0 0 / .125)');
    const disabledBg = Color(styles.primaryColor).grayscale();
    buttons.css('--bs-btn-disabled-color', contrastColor(disabledBg));
    buttons.css('--bs-btn-disabled-bg', disabledBg);
    buttons.css('--bs-btn-disabled-border-color', disabledBg);

    const formControls = $(form).find('.form-control');
    const primary = Color(styles.primaryColor);
    const fieldShadow = `0 0 0 .25rem rgba(${primary.red()} ${primary.green()} ${primary.blue()} / 0.25)`;
    formControls
        .focus(function () {
            $(this).css('box-shadow', fieldShadow);
            $(this).css('border-color', primary.darken(0.5).hex());
        })
        .blur(function () {
            $(this).css('box-shadow', '')
            $(this).css('border-color', '');
        });

    const checkmarks = $(form).find('.form-check-input');
    checkmarks.css('--fw-background-color', styles.backgroundColor);
    checkmarks.css('--fw-primary-color', styles.primaryColor);
    checkmarks.css('--fw-primary-highlight-color', lightenOrDarkenColor(styles.primaryColor, 0.5));
    checkmarks.css('--fw-field-shadow', fieldShadow);
}

function lightenOrDarkenColor(colorHex: string, amount: number): string {
    const color = Color(colorHex);
    if (color.isDark()) {
        return color.lighten(amount).hex();
    } else {
        return color.darken(amount).hex();
    }
}

function contrastColor(colorHex: string): string {
    const color = Color(colorHex);
    if (color.isDark()) {
        return Color('#ffffff').hex();
    } else {
        return Color('#000000').hex();
    }
}

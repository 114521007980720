import * as $ from "jquery";
import {BusinessAutocomplete} from "./business.autocomplete";
import {Style} from "../style_configuration";
import {Configuration} from "../configuration";

export const buildBusinessSearch = (configuration: Configuration, styles: Style, className: string) => ({
    // The template describes the HTML that the field will generate.
    // It uses Underscore.js templates.
    template: `
        <input  
            type="search"
            class="form-control<%= (fieldHtmlClass ? " " + fieldHtmlClass : "") %>"
            value="<%=escape(value)%>"
            name="<%= node.name %>"
            id="<%=node.id%>"
            aria-label="<%= node.title ? escape(node.title) : node.name %>"
            <%= (node.disabled? " disabled" : "")%>
            <%= (node.schemaElement && node.schemaElement.required ? " required=\\'required\\'" : "") %>
        />
    `,

    // Set the inputfield flag when the field is a real input field
    // that produces a value. Set the array flag when it creates an
    // array of fields. Both flags are mutually exclusive.
    // Do not set any of these flags for containers and other types
    // of fields.
    inputfield: true,
    array: false,

    // Most real input fields should set this flag that wraps the
    // generated content into the HTML code needed to report errors
    fieldtemplate: true,

    // Return the root element created by the field
    // (el is the DOM element whose id is node.id,
    // this function is only useful when el is not the root
    // element in the field's template)
    getElement: function (el) {
        return $(el).get(0);
    },

    // This is where you can complete the data that will be used
    // to run the template string
    onBeforeRender: function (data, node) {},

    // This is where you can enhance the generated HTML by adding
    // event handlers if needed
    onInsert: function (evt, node) {
        this.autoCompleteService = new BusinessAutocomplete(configuration, className, styles, node);
    },

    autoCompleteService: null,
});
import {Style, defaultStyles} from './style_configuration';
import { Request } from './request';

interface PreviewFormConfig {
    jsonSchema: string;
    jsonUiSchema: string;
    styles: Style;
}

interface FormData {
    formId: string;
    baseURL: string;
}

export class Configuration {
    formId: string;
    version: string;
    styleConfiguration: Style;
    baseURL: string;
    recaptchaSiteKey: string;
    previewSchema: string;
    previewUI: string;
    previewMode: boolean;
    hideSubmit: boolean;

    constructor(script: HTMLElement) {
        let formPreview: string = script?.getAttribute('preview-data');
        let formInfo: string = script?.getAttribute('data');

        this.styleConfiguration = Object.create(defaultStyles);
        this.hideSubmit = script?.getAttribute('hide-submit') === 'true';

        if (!!formPreview) {
            let previewData: PreviewFormConfig = JSON.parse(this.b64DecodeUnicode(formPreview));
            this.previewUI = previewData.jsonUiSchema;
            this.previewSchema = previewData.jsonSchema;
            this.setStyle(previewData.styles);
            this.previewMode = true;
            return;
        }
        if (!!formInfo) {
            let data: FormData = JSON.parse(this.b64DecodeUnicode(formInfo));
            this.formId = data.formId;
            this.baseURL = (data.baseURL || "https://forms-prod.apigateway.co")
            this.previewMode = false;
        }
    }

    setRecaptchaSiteKey(recaptchaSiteKey: string): void {
        this.recaptchaSiteKey = recaptchaSiteKey;
    }

    private b64DecodeUnicode(str: string): string {
        const decodedString: string = atob(str);

        // Maps over the string as an array, slicing it to UTF-8
        const uriEncoded = Array.prototype.map.call(decodedString, c => {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join('');

        return decodeURIComponent(uriEncoded);
    }

    getWidgetConfig(postCallback): void {
        if (this.previewMode) {
            postCallback({ jsonSchema: this.previewSchema, jsonUiSchema: this.previewUI, styles: this.styleConfiguration });
            return;
        }

        const payload = {
            'form_id': this.formId,
            "library": 1 // jsonform
        };
        Request.ajaxPost(this.baseURL + "/forms.v1.Forms/RenderForm", payload, postCallback);
    }

    setStyle(main: Style) {
        if (main) {
            this.styleConfiguration = {
                backgroundColor: main.backgroundColor || this.styleConfiguration.backgroundColor,
                borderColor: main.borderColor || this.styleConfiguration.borderColor,
                borderRadius: main.borderRadius || this.styleConfiguration.borderRadius,
                borderStyle: main.borderStyle || this.styleConfiguration.borderStyle,
                borderWidth: main.borderWidth || this.styleConfiguration.borderWidth,
                padding: main.padding || this.styleConfiguration.padding,
                width: main.width || this.styleConfiguration.width,
                primaryFontColor: main.primaryFontColor || this.styleConfiguration.primaryFontColor,
                primaryColor: main.primaryColor || this.styleConfiguration.primaryColor,
                onPrimaryColor: main.onPrimaryColor || this.styleConfiguration.onPrimaryColor,
            };
        }
    }

    populateFieldsByQueryParam(fieldParams: PopulateFieldByQueryParam[]): object {
        const fieldValues = {};
        const queryToField: Map<string, string[]> = new Map();
        fieldParams.forEach(fieldParam => {
            if (fieldParam?.fieldId && fieldParam?.queryParam) {
                if (queryToField.has(fieldParam?.queryParam)) {
                    const mappedFields = queryToField.get(fieldParam?.queryParam);
                    mappedFields.push(fieldParam.fieldId);
                    queryToField.set(fieldParam?.queryParam, mappedFields);
                } else {
                    queryToField.set(fieldParam?.queryParam, [fieldParam?.fieldId]);
                }
            }
        })
        const urlParams = new URLSearchParams(document.location.search);
        urlParams.forEach((value, urlParam) => {
            if (queryToField.has(urlParam)) {
                const fieldIds = queryToField.get(urlParam);
                for (const fieldId of fieldIds) {
                    fieldValues[fieldId] = value
                }
            }
        });
        return fieldValues;
    }
}

export interface PopulateFieldByQueryParam {
    fieldId: string,
    queryParam: string,
}
